import React, { useEffect, useState } from 'react'
import { enumUtil } from '../../../utils/Enum';
import NotificationHandler from '../../../utils/NotificationHandler';
import { ServerRequestProtected } from '../../../utils/ServerDataRequest';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import isNull from '../../../utils/NullChecking';
import { DateFormat_YMd_Time, DateFormatCalculations } from '../../../utils/Constants';

const OverAllSessionDetails = (props) => {

    const { type, SearchType, CurrentDate } = { ...props }
    let token = useSelector(state => state.TokenReducer);
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let Token = token && jwt_decode(token);
    let UserID = "";
    if (!isNull(type) && type === "Employee") {
        UserID = activeEmployee + "";
    }
    //  else {
    //     UserID = Token.UserID + "";
    // }

    const [isLoading, setIsLoading] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const [TrackingData, setTrackingData] = useState([])

    const LoadData = () => {
        setTrackingData([]);
        setIsLoading(true);
        let saveModal = {
            "TableName": enumUtil.enumAPITableName.ActivitySession,
            "SearchCriteria": {
                "EmployeeID": UserID + "",
                "Date": CurrentDate,
                "Session": SearchType,
            }
        }
        ServerRequestProtected(API_URL + "/GetActimityTime", "post", saveModal).then((response) => {
            setIsLoading(false);
            setTrackingData(response);
            // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error?.message, enumUtil.enumtoaster.error)
        })
    }

    const dateComparison = (date1, date2) => {
        let comparisonResult = false;
        if (!isNull(date1)) {
            let Date1 = new Date(date1);
            let currentDate = new Date();
            Date1.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);
            comparisonResult = Date1.getTime() === currentDate.getTime()
        }
        return comparisonResult
    }

    useEffect(() => {
        LoadData()
        if (CurrentDate) {
            dateComparison(CurrentDate);
        }
    }, [CurrentDate])

    return (
        <div>
            {TrackingData?.map((item) => {
                return (
                    <div className='task_timing ms-3 me-2 mt-1'>
                        {console.log("dateComparison", dateComparison(CurrentDate))}
                        <div>
                            <p className='heading'><span className='checkin_clr'>{(item?.StartTime || '00:00')}</span> - <span className='checkout_clr'>{(item?.EndTime || '00:00')}</span></p>
                            <p className='text clr_gry sm'>In/Out Time</p>
                        </div>
                        <div>
                            <p className='heading'>{item?.TodayTotal ? item?.TodayTotal : dateComparison(CurrentDate) ? '00:00' :  <span className='checkout_clr'>Check Out Time Missing!</span>}</p>
                            <p className='text clr_gry sm'>Today’s Total</p>
                        </div>
                        <div className="ze"></div>
                        <div>
                            <p className='heading'><span className=''>{(item?.ActivityStartTime || '00:00')}</span> - <span className=''>{(item?.ActivityEndTime || '00:00')}</span></p>
                            <p className='text clr_gry sm '>Session Time</p>
                        </div>
                        <div>
                            <p className='heading'>{item?.ActivityTime}</p>
                            <p className='text clr_gry sm'>Active Time</p>
                        </div>
                        <div>
                            <div class="d-flex gap-1 align-items-center ranges">
                                <div class="progress range w-100">
                                    <div class="progress-bar fill" style={{ width: `${item?.Activity}%` }}></div>
                                </div>
                                <p class="heading precentage">{item?.Activity + "%"}</p>
                            </div>
                            <p className='gry_heading'>Activity</p>
                        </div>

                    </div>
                )
            })
            }
        </div>
    )
}

export default OverAllSessionDetails
