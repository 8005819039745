import React, { useEffect, useReducer, useState } from "react";
import { MonthsDropdown } from "../../../utils/Constants";
import ReactDataGrid from "react-data-grid";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "reactstrap";
import {
  ServerRequestProtected,
  ServerRequestProtectedParentID,
} from "../../../utils/ServerDataRequest";
import { enumUtil } from "../../../utils/Enum";
import Loader from "../../shared/Loader/Loader";
import close from "../../../assetsOld/img/x-close.svg";
import ModalFooter from "../../shared/ModalFooter/ModalFooter";
import AddNewRequest from "./AddNewRequest/AddNewRequest";
import { Nav, Tab, Tabs } from "react-bootstrap";
import CardView from "./AdminRequestViews/CardView";
import TableView from "./AdminRequestViews/TableView";

const LeaveRequest = (props) => {
  const { type } = { ...props };
  const currentMonth = new Date().getMonth() + 1;
  let currentMonthNum = new Date().getMonth() + 1;
  let monthIndex = MonthsDropdown?.findIndex(it => it.value == currentMonth);
  let monthsDD = monthIndex !== -1 ? MonthsDropdown?.slice(0, monthIndex + 1) : MonthsDropdown;

  let TimeAdjustment_Modal = {
    Month: currentMonth + "",
  };

  const API_URL = process.env.REACT_APP_API;
  const API_URL_DASHBOARD = process.env.REACT_APP_API_Dashboard;
  const [isLoading, setIsLoading] = useState(false);
  const [mainDropDownModal, setMainDropDownModal] = useState(TimeAdjustment_Modal);
  const [saveDropDownModal, setSaveDropDownModal] = useState({});
  const [NewRquest, setNewRquest] = useState(false);
  const [AllRequestData, setAllRequestData] = useState([]);
  const [isOpenRequest, setIsOpenRequest] = useState(false);
  const [RequestID, setRequestID] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [OverAllRecord, setOverallRecord] = useState({});
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(null);
  const [RequestType, setRequestType] = useState(null);
  const [EmployeeName, setEmployeeName] = useState(null);
  const [Enter, setEnter] = useState();
  const [gridHeader, handleGridHeader] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, { defaultData: '', activeSortIcon: '' })

  let Srchdata = {
    // "RequestType": RequestType,
    RequestStatus: status,
    SearchLetter: EmployeeName,
  };
  const SearchStatus = (e) => {
    if (e.target.value === "") {
      setStatus(null);
    } else {
      setStatus(e.target.value);
    }
  };
  const SearchRequestType = (e) => {
    if (e.target.value === "") {
      setRequestType(null);
    } else {
      setRequestType(e.target.value);
    }
  };
  const SearchEmployeeName = (e) => {
    if (e.target.value === "") {
      setEmployeeName(null);
    } else {
      setEmployeeName(e.target.value);
    }
  };
  const SearchOnEnter = (e) => {
    if (e.key === "Enter") {
      setEnter(e.target.value.trim(""));
      setEmployeeName(e.target.value.trim(""));
      /// change ///
      setStatus(null);
      setRequestType(null);
      setSearch(null);
      LoadData(Srchdata);
    }
  };
  const LoadRequestData = (Srchdata) => {
    LoadData(Srchdata);
    RequestCount(Srchdata);
  };
  const columns = [
    {
      key: "EmployeeID",
      name: "Employee ID",
      resizable: true,
      flex: 1,
      // formatter: ({ row }) => {
      //     return (
      //         <button className='btn' onClick={""}>
      //             {row?.CategoryName}
      //         </button>
      //     )
      // }
    },
    {
      key: "EmployeeName",
      name: "Employee Name",
      resizable: true,
      flex: 1,
      formatter: ({ row }) => {
        return (
          <div className="validation_message" title={row?.EmployeeName}>
            {row?.EmployeeName}
          </div>
        );
      },
    },
    {
      key: "Type",
      name: "Request Type",
      resizable: true,
      flex: 1,
      formatter: ({ row }) => {
        return (
          <div className="validation_message" title={row?.Type}>
            {row?.Type}
          </div>
        );
      },
    },
    {
      key: "RequestedDate",
      name: "Requested Date",
      resizable: true,
      flex: 1,
    },
    {
      key: "Description",
      name: "Description",
      resizable: true,
      flex: 1,
      formatter: ({ row }) => {
        return (
          <div className="validation_message" title={row?.Description}>
            {row?.Description}
          </div>
        );
      },
    },
    {
      key: "AppliedOn",
      name: "Applied On",
      resizable: true,
      flex: 1,
    },
    {
      key: "ApprovedDate",
      name: "Approved Date",
      resizable: true,
      flex: 1,
    },
    {
      key: "ApprovedBy",
      name: "Approved By",
      resizable: true,
      flex: 1,
    },
    {
      key: "Status",
      name: "Status",
      resizable: true,
      flex: 1,
      formatter: ({ row }) => {
        return <div className={`${row?.Status}`}>{row?.Status}</div>;
      },
    },
  ];

  let handleMonthChange = (e) => {
    const { name, value, selectedIndex } = e.target;

    let modal = { ...mainDropDownModal };
    let saveModal = { ...saveDropDownModal };
    const selectedOptionText = e.target.options[selectedIndex].text;
    modal[name] = value;

    setMainDropDownModal(modal);
    for (var property in TimeAdjustment_Modal) {
      if (name === property) {
        saveModal[name] = value;
      }
    }
    setSaveDropDownModal(saveModal);
  };

  // const LoadData = (Srchdata) => {
  //     setAllRequestData([]);
  //     setIsLoading(true);
  //     let saveModal = {
  //         "TableName": enumUtil.enumAPITableName.Dashboard,
  //         "SearchParam": "Request",
  //         "SearchCriteria": {
  //             "RequestBy": "Month",
  //             "Month": mainDropDownModal.Month,
  //             "RequestAccess": "All",
  //             "RequestlistFor": "Leave",
  //             ...Srchdata
  //         }
  //     }
  //     ServerRequestProtected(API_URL_DASHBOARD + '/GetRequest', "post", saveModal
  //     ).then((response) => {
  //         setIsLoading(false);
  //         setAllRequestData(response);
  //         // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
  //     }).catch((error) => {
  //         setIsLoading(false);
  //         // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
  //     })
  // }
  const LoadData = (Srchdata) => {
    setAllRequestData([]);
    setIsLoading(true);
    ServerRequestProtected(API_URL + "/FindBySingleCriteria", "post", {
      TableName: enumUtil.enumAPITableName.EmployeeLeave,
      SearchCriteria: {
        // "UserID": UserID,
        // "ParentID": OrgID,
        Month: mainDropDownModal.Month,
        RequestAccess: "All",
        ...Srchdata,
      },
    })
      .then((response) => {
        setIsLoading(false);
        setAllRequestData(response);
        handleGridHeader({defaultData: response})
        // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
      })
      .catch((error) => {
        setIsLoading(false);
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
      });
  };
  const RequestCount = (Srchdata) => {
    setOverallRecord([]);
    setIsLoading(true);
    ServerRequestProtectedParentID(API_URL + "/GetRequestCunt", "post", {
      TableName: enumUtil.enumAPITableName.REQUEST,
      SearchCriteria: {
        ...mainDropDownModal,
        RequestlistFor: "Leave",
        RequestAccess: "All",
        RequestStatus: "",
        RequestType: "",
        Search: "",
        ...Srchdata,
      },
    })
      .then((response) => {
        setIsLoading(false);
        setOverallRecord(response[0]);
        // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
      })
      .catch((error) => {
        setIsLoading(false);
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
      });
  };

  const ClearFilters = () => {
    setEmployeeName('');
    setStatus('');
  }
  const AddRequest = (ID) => {
    setIsOpenRequest(true);
    setRequestID(ID);
  };

  const CloseRequest = () => {
    setIsOpenRequest(false);
    if (isRefresh) {
      LoadData(Srchdata);
      RequestCount(Srchdata);
    }
  };

  let PopupScreen = "";
  if (isOpenRequest) {
    PopupScreen = (
      <AddNewRequest
        isOpenTask={isOpenRequest}
        onClose={CloseRequest}
        ID={RequestID}
        setIsRefresh={setIsRefresh}
        type={type}
      />
    );
  }

  useEffect(() => {
    LoadData();
    RequestCount();
  }, [mainDropDownModal.Month]);

  return (
    <div>
      {PopupScreen}
      {isLoading ? <Loader /> : <></>}
      <Tab.Container defaultActiveKey="TableView">
        <div className="ar_my-request-screen">
          <div className="row flex-grow-1 m-0 mt-1">
            <div className="col-3 employee_screen pe-0">
              <div className="form-group input">
                <input
                  type="text"
                  className="form-control rounded"
                  placeholder="First Name,Last Name/ID"
                  value={EmployeeName}
                  onChange={(e) => setEmployeeName(e.target.value.trim(""))}
                  onKeyDown={SearchOnEnter}
                />
              </div>
            </div>
            {/* <div className="col-3">
                            <div className="input">
                                <div className="input-group">
                                    <input type="text" className="form-control"
                                        placeholder="Search Request Type"
                                        onChange={e => setRequestType(e.target.value.trim(""))}
                                    // onKeyDown={SearchOnEnter}
                                    />
                                </div>
                            </div>
                        </div> */}
            <div className="col-3 pe-0">
              <div className="input">
                <div className="form-group">
                  <select
                    type="text"
                    className="form-control rounded"
                    placeholder="Search Status"
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                  // onKeyDown={SearchOnEnter}
                  >
                    <option value={""}></option>
                    <option value={"Pending"}>Pending</option>
                    <option value={"Dennied"}>Denied</option>
                    <option value={"Approved"}>Approved</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-1">
              <button
                className="btn btn-orange no-width rounded w-100"
                type="button"
                onClick={() => LoadRequestData(Srchdata)}
              >
                Search
              </button>
            </div>
            <div className="col-1">
              <button
                className="btn btn-gray no-width rounded w-100"
                type="button"
                onClick={() => ClearFilters()}
              >
                Clear
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between my-3 px-2 mx-1 ">
            {/* <p className='heading clr_drk-blue fs-14 doc  px-2 py-1'>Leave Requests</p> */}
            <div className="col-md-7 d-flex justify-content-between align-items-center">
              <div className="d-flex gap-2 leave_req_screen h-auto align-items-center">
                <div className="left_border all"></div>
                <div>
                  <p className="heading">Total Request</p>
                  <p className="heading sm">
                    {OverAllRecord?.TotalRequest || 0}
                  </p>
                </div>
                <div className="left_border new"></div>
                <div>
                  <p className="heading"> New Request</p>
                  <p className="heading sm">{OverAllRecord?.NewRequest || 0}</p>
                </div>
                <div className="left_border pending"></div>
                <div>
                  <p className="heading">Pending </p>
                  <p className="heading sm">
                    {OverAllRecord?.PendingRequest || 0}
                  </p>
                </div>
                <div className="left_border approved"></div>
                <div>
                  <p className="heading">Approved </p>
                  <p className="heading sm">
                    {OverAllRecord?.ApprovedRequest || 0}
                  </p>
                </div>
                <div className="left_border denied"></div>
                <div>
                  <p className="heading">Denied </p>
                  <p className="heading sm">
                    {OverAllRecord?.DeniedRequest || 0}
                  </p>
                </div>
              </div>
              <div className="ar_request_screen ">
                <Nav variant="tabs" className="rb-tabs-v3 tabs_view mb-2">
                  <Nav.Item>
                    <Nav.Link eventKey="TableView">
                      {" "}
                      <i className="bi bi-grid fs-12"></i> Table
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="CardView">
                      <i className="icon-tab-card fs-12"></i> Card
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
            <div className="d-flex align-items-center gap-10">
              {/* <button className='btn btn-blue rounded' onClick={() => AddRequest(0)}>Add Request </button> */}
              <div className="input">
                <select
                  className="form-control fc-xs"
                  id="Month"
                  name="Month"
                  value={mainDropDownModal.Month}
                  onChange={(e) => handleMonthChange(e)}
                >
                  {monthsDD.map((item) => {
                    return <option value={item.value}>{item.display}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="TableView">
              <TableView ColumnsData={AllRequestData} LoadData={LoadData} RequestCount={RequestCount} gridHeader={gridHeader} handleGridHeader={handleGridHeader}/>
              {isLoading ? null : AllRequestData &&
                AllRequestData?.length === 0 ? (
                <div className="no_data">
                  <span class="icon-ar-nodata">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                    <span class="path6"></span>
                    <span class="path7"></span>
                    <span class="path8"></span>
                    <span class="path9"></span>
                    <span class="path10"></span>
                    <span class="path11"></span>
                    <span class="path12"></span>
                    <span class="path13"></span>
                    <span class="path14"></span>
                    <span class="path15"></span>
                    <span class="path16"></span>
                    <span class="path17"></span>
                    <span class="path18"></span>
                    <span class="path19"></span>
                    <span class="path20"></span>
                    <span class="path21"></span>
                    <span class="path22"></span>
                    <span class="path23"></span>
                    <span class="path24"></span>
                    <span class="path25"></span>
                    <span class="path26"></span>
                    <span class="path27"></span>
                    <span class="path28"></span>
                    <span class="path29"></span>
                    <span class="path30"></span>
                    <span class="path31"></span>
                    <span class="path32"></span>
                    <span class="path33"></span>
                    <span class="path34"></span>
                    <span class="path35"></span>
                    <span class="path36"></span>
                    <span class="path37"></span>
                    <span class="path38"></span>
                    <span class="path39"></span>
                    <span class="path40"></span>
                    <span class="path41"></span>
                    <span class="path42"></span>
                    <span class="path43"></span>
                    <span class="path44"></span>
                    <span class="path45"></span>
                    <span class="path46"></span>
                    <span class="path47"></span>
                    <span class="path48"></span>
                    <span class="path49"></span>
                    <span class="path50"></span>
                    <span class="path51"></span>
                    <span class="path52"></span>
                    <span class="path53"></span>
                    <span class="path54"></span>
                    <span class="path55"></span>
                    <span class="path56"></span>
                    <span class="path57"></span>
                    <span class="path58"></span>
                    <span class="path59"></span>
                    <span class="path60"></span>
                    <span class="path61"></span>
                    <span class="path62"></span>
                    <span class="path63"></span>
                    <span class="path64"></span>
                    <span class="path65"></span>
                    <span class="path66"></span>
                    <span class="path67"></span>
                    <span class="path68"></span>
                    <span class="path69"></span>
                    <span class="path70"></span>
                    <span class="path71"></span>
                    <span class="path72"></span>
                    <span class="path73"></span>
                    <span class="path74"></span>
                    <span class="path75"></span>
                    <span class="path76"></span>
                    <span class="path77"></span>
                    <span class="path78"></span>
                    <span class="path79"></span>
                    <span class="path80"></span>
                    <span class="path81"></span>
                    <span class="path82"></span>
                    <span class="path83"></span>
                    <span class="path84"></span>
                    <span class="path85"></span>
                    <span class="path86"></span>
                    <span class="path87"></span>
                    <span class="path88"></span>
                    <span class="path89"></span>
                    <span class="path90"></span>
                    <span class="path91"></span>
                    <span class="path92"></span>
                    <span class="path93"></span>
                    <span class="path94"></span>
                    <span class="path95"></span>
                    <span class="path96"></span>
                    <span class="path97"></span>
                    <span class="path98"></span>
                    <span class="path99"></span>
                    <span class="path100"></span>
                    <span class="path101"></span>
                    <span class="path102"></span>
                    <span class="path103"></span>
                    <span class="path104"></span>
                    <span class="path105"></span>
                    <span class="path106"></span>
                    <span class="path107"></span>
                    <span class="path108"></span>
                    <span class="path109"></span>
                    <span class="path110"></span>
                    <span class="path111"></span>
                    <span class="path112"></span>
                    <span class="path113"></span>
                    <span class="path114"></span>
                    <span class="path115"></span>
                    <span class="path116"></span>
                    <span class="path117"></span>
                    <span class="path118"></span>
                    <span class="path119"></span>
                    <span class="path120"></span>
                    <span class="path121"></span>
                    <span class="path122"></span>
                    <span class="path123"></span>
                    <span class="path124"></span>
                    <span class="path125"></span>
                    <span class="path126"></span>
                    <span class="path127"></span>
                    <span class="path128"></span>
                  </span>
                  <p className="text fst-italic text-center font-m">
                    No Data Found!
                  </p>
                </div>
              ) : null}
            </Tab.Pane>
            <Tab.Pane eventKey="CardView">
              <CardView
                ColumnsData={AllRequestData}
                LoadData={LoadData}
                filterItem={"LeaveStatus"}
                RequestCount={RequestCount}
              />
              {isLoading ? null : AllRequestData &&
                AllRequestData?.length === 0 ? (
                <div className="no_data">
                  <span class="icon-ar-nodata">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                    <span class="path6"></span>
                    <span class="path7"></span>
                    <span class="path8"></span>
                    <span class="path9"></span>
                    <span class="path10"></span>
                    <span class="path11"></span>
                    <span class="path12"></span>
                    <span class="path13"></span>
                    <span class="path14"></span>
                    <span class="path15"></span>
                    <span class="path16"></span>
                    <span class="path17"></span>
                    <span class="path18"></span>
                    <span class="path19"></span>
                    <span class="path20"></span>
                    <span class="path21"></span>
                    <span class="path22"></span>
                    <span class="path23"></span>
                    <span class="path24"></span>
                    <span class="path25"></span>
                    <span class="path26"></span>
                    <span class="path27"></span>
                    <span class="path28"></span>
                    <span class="path29"></span>
                    <span class="path30"></span>
                    <span class="path31"></span>
                    <span class="path32"></span>
                    <span class="path33"></span>
                    <span class="path34"></span>
                    <span class="path35"></span>
                    <span class="path36"></span>
                    <span class="path37"></span>
                    <span class="path38"></span>
                    <span class="path39"></span>
                    <span class="path40"></span>
                    <span class="path41"></span>
                    <span class="path42"></span>
                    <span class="path43"></span>
                    <span class="path44"></span>
                    <span class="path45"></span>
                    <span class="path46"></span>
                    <span class="path47"></span>
                    <span class="path48"></span>
                    <span class="path49"></span>
                    <span class="path50"></span>
                    <span class="path51"></span>
                    <span class="path52"></span>
                    <span class="path53"></span>
                    <span class="path54"></span>
                    <span class="path55"></span>
                    <span class="path56"></span>
                    <span class="path57"></span>
                    <span class="path58"></span>
                    <span class="path59"></span>
                    <span class="path60"></span>
                    <span class="path61"></span>
                    <span class="path62"></span>
                    <span class="path63"></span>
                    <span class="path64"></span>
                    <span class="path65"></span>
                    <span class="path66"></span>
                    <span class="path67"></span>
                    <span class="path68"></span>
                    <span class="path69"></span>
                    <span class="path70"></span>
                    <span class="path71"></span>
                    <span class="path72"></span>
                    <span class="path73"></span>
                    <span class="path74"></span>
                    <span class="path75"></span>
                    <span class="path76"></span>
                    <span class="path77"></span>
                    <span class="path78"></span>
                    <span class="path79"></span>
                    <span class="path80"></span>
                    <span class="path81"></span>
                    <span class="path82"></span>
                    <span class="path83"></span>
                    <span class="path84"></span>
                    <span class="path85"></span>
                    <span class="path86"></span>
                    <span class="path87"></span>
                    <span class="path88"></span>
                    <span class="path89"></span>
                    <span class="path90"></span>
                    <span class="path91"></span>
                    <span class="path92"></span>
                    <span class="path93"></span>
                    <span class="path94"></span>
                    <span class="path95"></span>
                    <span class="path96"></span>
                    <span class="path97"></span>
                    <span class="path98"></span>
                    <span class="path99"></span>
                    <span class="path100"></span>
                    <span class="path101"></span>
                    <span class="path102"></span>
                    <span class="path103"></span>
                    <span class="path104"></span>
                    <span class="path105"></span>
                    <span class="path106"></span>
                    <span class="path107"></span>
                    <span class="path108"></span>
                    <span class="path109"></span>
                    <span class="path110"></span>
                    <span class="path111"></span>
                    <span class="path112"></span>
                    <span class="path113"></span>
                    <span class="path114"></span>
                    <span class="path115"></span>
                    <span class="path116"></span>
                    <span class="path117"></span>
                    <span class="path118"></span>
                    <span class="path119"></span>
                    <span class="path120"></span>
                    <span class="path121"></span>
                    <span class="path122"></span>
                    <span class="path123"></span>
                    <span class="path124"></span>
                    <span class="path125"></span>
                    <span class="path126"></span>
                    <span class="path127"></span>
                    <span class="path128"></span>
                  </span>
                  <p className="text fst-italic text-center font-m">
                    No Data Found!
                  </p>
                </div>
              ) : null}
            </Tab.Pane>
          </Tab.Content>

          {/* <div className='table-view all_employee'>
                        <ReactDataGrid
                            enableVirtualization={false}
                            // virtualizeColumns={columns.length > 10}
                            columns={columns}
                            rows={AllRequestData}
                            rowHeight={30}
                            className={"fill-grid DataGrid-Table hk-exp-table all_employee_grid"}
                            style={{
                                width: '100%',
                                // height:'100px',
                                // maxHeight: '330px',
                            }}
                        />
                    </div> */}
        </div>
      </Tab.Container>
    </div>
  );
};

export default LeaveRequest;
